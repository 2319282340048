import { Checkbox, Collapse, Input, Select, Spin, Tooltip } from 'antd';
import { cloneDeep, find, get, isEmpty, isNaN, orderBy, union, uniqBy } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { BasicButton } from '../../../../../../assets/common';
import {
  avatar,
  chevron_down,
  descending_sort,
  edit_filtered_result,
  recommendation_filter,
  sp_chevron_down,
  sp_comment,
  sp_recomendation_table_comment,
  star,
} from '../../../../../../assets/img';
import { useGetMeeting } from '../../../../../../hooks/useGetMeeting';
import { useGetRoleInMeeting } from '../../../../../../hooks/useGetRoleInMeeting';
import { talentReviewApi } from '../../../../../../services/talentReviewApi';
import { meetingApi } from '../../../../../../services/tcMeeting';
import { MEETING_DETAIL_STATUS, SHOW_REMARK_ICON_STATUS } from '../../../../../../utils/constants';
import {
  calculateTime,
  decodeBase64ToImage,
  handleShowRetirementDate,
  handleShowTextTooltip,
  handleShowTooltip,
  infoPosition,
} from '../../../../../../utils/helper';
import RemarkTableDialog from '../../RemarkTableDialog/RemarkTableDialog';
import PopupMatchingCriteriaList from '../../../../../../components/PopupMatchingCriteriaList/PopupMatchingCriteriaList';
import AddRemark from '../AddRemark/AddRemark';
import { Draggable } from '../wrappers/Draggable';
import FromTalentReview from './FromTalentReview/FromTalentReview';
import styles from './recommendation-table.module.scss';
import { Wrapper } from './styled';

const { Panel } = Collapse;

const Item = (props) => {
  const {
    item,
    index,
    text,
    handleSelected,
    selected,
    showCheckBox,
    handleViewMatchingPercentage,
  } = props;
  const [collapseMenu, setCollapseMenu] = useState(null);
  const positions = useSelector(
    (state) => state.app.meetingModule.agenda.agendaIncumbent.positions
  );
  const { status } = useSelector((state) => state.app.meetingModule.preMeeting.meeting);
  const agendaParam = useParams();

  const [showAddRemark, setShowAddRemark] = useState({
    status: false,
    staff: '',
  });
  const [loadingPhoto, setLoadingPhoto] = useState(false);
  function showHide(staff_id) {
    collapseMenu ? setCollapseMenu(null) : setCollapseMenu(staff_id);
  }

  const handleShowRetirement = (item) => {
    let total =
      Number(moment(item?.retirement_date).format('YYYY')) -
      Number(moment(item?.birth_date, 'DD-MM-YYYY').format('YYYY'));
    if (!isNaN(total)) {
      return '(' + total + ')';
    }

    return '';
  };

  const getPhoto = async () => {
    setLoadingPhoto(true);
    try {
      const rs = await meetingApi.getPhotoByStaffId(item.staff_id);
      if (!rs) return '';
      item.image = rs.data.result.length > 0 ? rs.data.result[0].image : '';
      item.isLoadedPhoto = true;
      setLoadingPhoto(false);
    } catch (error) {
      setLoadingPhoto(false);
    }
  };
  useEffect(() => {
    if (!collapseMenu) return;
    if (item.isLoadedPhoto) return;
    getPhoto();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapseMenu]);

  return (
    <>
      {showAddRemark.status && (
        <AddRemark setIsVisible={setShowAddRemark} showAddRemark={showAddRemark} />
      )}
      <Collapse>
        <Panel
          showArrow={false}
          header={
            <div
              onKeyDown={() => {}}
              onClick={() => showHide(item.staff_id)}
              className={styles.tableBody}
              id={item.staff_id}
            >
              {showCheckBox && (
                <div
                  style={{ width: '5%' }}
                  onKeyDown={() => {}}
                  onClick={(event) => event.stopPropagation()}
                >
                  <Checkbox
                    onChange={() => handleSelected(item.staff_id)}
                    checked={selected.includes(item.staff_id)}
                  />
                </div>
              )}
              <div style={{ width: '10%' }}>{index + 1}</div>
              <div
                className={styles.name}
                style={{ width: showCheckBox && text === 'filtered' ? '40%' : '50%' }}
              >
                <Link
                  to={`/profile/${item.staff_id}`}
                  target="_blank"
                  className={styles.recommendation__name}
                  draggable="false"
                >
                  {item.birth_name}
                </Link>
              </div>
              {showCheckBox && text === 'filtered' && (
                <div style={{ width: '10%' }}>Set {item.set_number}</div>
              )}

              <div
                style={{
                  width: 80,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
                title={item?.salary_grade}
              >
                {item?.salary_grade}
              </div>
              <div
                style={{
                  width: '20%',
                  color: 'var(--light-primary-500, #00A19C)',
                }}
                className="text-left d-flex  justify-content-between align-items-center"
              >
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    handleViewMatchingPercentage(item);
                  }}
                >
                  {item.matching_percentage && item.matching_percentage + '%'}{' '}
                </span>
                {/* {item.isStar && (
                  <Tooltip
                    placement="top"
                    title={
                      <>
                        <div>
                          <span
                            style={{
                              color: 'var(--base-white, #FFF)',
                              fontSize: '12px',
                              fontWeight: 600,
                              lineHeight: '18px',
                            }}
                          >
                            Matching of Aspiration
                          </span>
                        </div>
                        {!isEmpty(item.minilmjobfunctions) &&
                          item.minilmjobfunctions.map((i, index) => (
                            <div style={{ marginTop: '5px' }} key={index}>
                              <span
                                style={{
                                  height: '5px',
                                  width: '5px',
                                  backgroundColor: '#fff',
                                  borderRadius: '50%',
                                  display: 'inline-block',
                                  transform: 'translateY(-2px)',
                                  marginRight: '8px',
                                  marginLeft: '8px',
                                  opacity: '0.8',
                                }}
                              ></span>
                              <span
                                style={{
                                  color: 'var(--base-white, #FFF)',
                                  fontSize: '12px',
                                  fontWeight: 400,
                                  lineHeight: '18px',
                                  opacity: '0.8',
                                }}
                              >
                                {i}
                              </span>
                            </div>
                          ))}
                      </>
                    }
                    overlayClassName="custom-tooltip"
                    {...{
                      overlayInnerStyle: {
                        width: '260px',
                        fontSize: '12px',
                        lineHeight: '18px',
                        gap: '24px',
                        padding: '12px',
                        borderRadius: '4px',
                        backgroundColor: '#000',
                      },
                    }}
                  >
                    <span style={{ marginRight: '30px', cursor: 'pointer' }}>
                      <img src={star} alt="star" style={{ width: '16px' }} />
                    </span>
                  </Tooltip>
                )} */}
              </div>
              <div style={{ width: '5%' }}>
                <img src={sp_chevron_down} alt="" className={collapseMenu ? styles.animate : ``} />
              </div>
            </div>
          }
          key={item.staff_id}
        >
          <div className={styles.card_box}>
            <div className="row no-gutters justify-content-center align-items-stretch">
              <Draggable
                id={`${text}-drap-${index}`}
                className={`collapseBody__lineRecommendation col-3 ${styles.left}`}
                style={{ backgroundColor: '#d9f2f7' }}
                data={{ ...item, index }}
              >
                <div className="row flex-column no-gutters align-items-center mt-3">
                  <div className={styles.image}>
                    <Spin spinning={loadingPhoto} size="small">
                      <img
                        src={decodeBase64ToImage(item.image) || avatar}
                        alt=""
                        width={80}
                        height={80}
                        style={{ borderRadius: '50%' }}
                      />
                    </Spin>
                  </div>
                  <div style={{ marginTop: '5px', fontSize: '12px', fontWeight: 'bold' }}>
                    {item.staff_id}
                  </div>
                  <div className={styles.position}>
                    <Tooltip
                      placement="bottom"
                      title={handleShowTextTooltip(item, positions)}
                      color={'black'}
                      key={'black'}
                      overlayInnerStyle={{ borderRadius: '6px' }}
                    >
                      {handleShowTooltip(item, positions)}
                    </Tooltip>
                  </div>
                </div>
              </Draggable>

              <div className={`col-9`} style={{ padding: '10px' }}>
                <div className={`${styles.right}`}>
                  <div className="mb-2">
                    {agendaParam?.agendaId && SHOW_REMARK_ICON_STATUS.includes(status) && (
                      <img
                        src={sp_comment}
                        alt=""
                        style={{ cursor: 'pointer' }}
                        onKeyDown={() => {}}
                        onClick={() =>
                          setShowAddRemark({
                            status: true,
                            staff: item,
                          })
                        }
                      />
                    )}
                  </div>
                  <Draggable
                    id={`${text}-drap-${index}`}
                    className="collapseBody__line"
                    data={{ ...item, index }}
                  >
                    <div className="mb-2" style={{ fontFamily: "'Inter', sans-serif" }}>
                      <Tooltip
                        placement="top"
                        title={infoPosition(
                          item.position_name,
                          item.department_name,
                          item.division,
                          item.opu,
                          item.business_unit
                        )}
                        color={'black'}
                        key={'black'}
                        overlayInnerStyle={{ borderRadius: '6px', width: '400px' }}
                      >
                        <span>Current Position: </span>
                        {item.position_name}, {item.business_unit}
                      </Tooltip>
                    </div>
                    <div className="mb-2" style={{ fontFamily: "'Inter', sans-serif" }}>
                      <span>YIP: </span>
                      {calculateTime(item.date_in_position)} &#160;&#160;
                      <span>Age: </span>
                      {item.age} &#160;&#160;
                      <span>YISG: </span>
                      {item.salary_grade} ({calculateTime(item.date_in_sg)})
                    </div>
                    <div className="mb-2" style={{ fontFamily: "'Inter', sans-serif" }}>
                      <span>Retirement: </span>
                      {handleShowRetirementDate(item.retirement_date)} {handleShowRetirement(item)}
                    </div>
                    <div className="mb-2" style={{ fontFamily: "'Inter', sans-serif" }}>
                      <span>Performance Rating: </span>
                      {item?.performance_rating}
                    </div>
                  </Draggable>
                </div>
              </div>
            </div>
          </div>
        </Panel>
      </Collapse>
    </>
  );
};

const Table = (props) => {
  const {
    showTable,
    setTable,
    text,
    showFilterRecommendation,
    table,
    addFromAToB,
    getFromAiTableB,
    roleActive,
    positionsCode,
    positionName,
    agendaId,
    fromAiParam,
    setFromAiParam,
    optionSearch,
    getFromAiTableA,
    orderPercentage,
    line,
    showCheckBoxA,
    setTemporaryTableB,
    temporaryTableB,
    setStatusTableB,
    statusTableB,
    showCheckBoxB,
    setCollapse,
    selectedOptionSet,
    name,
  } = props;
  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState('asc');
  const [openPopupCriteria, setOpenPopupCriteria] = useState(false);
  const [viewCriteriaParams, setViewCriteriaParams] = useState({
    staffId: '',
    idSet: '',
    positionCode: '',
    positionName: '',
    nameSet: '',
    run_id: '',
  });

  const handleViewMatchingPercentage = (item) => {
    setViewCriteriaParams({
      positionCode: item.position_code,
      staffId: item.staff_id,
      idSet: item.set_id,
      nameSet: item.set_name,
      run_id: item?.run_id,
    });
    setOpenPopupCriteria(true);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = showTable.map((row) => row.staff_id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleSelected = (_id) => {
    const selectedIndex = selected.indexOf(_id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, _id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const checkFilterMatching = (talent) => {
    if (fromAiParam.salary_grade && talent.salary_grade !== fromAiParam.salary_grade) {
      return false;
    }
    if (fromAiParam.birth_name && !talent.birth_name.includes(fromAiParam.birth_name)) {
      return false;
    }
    if (fromAiParam.match_from && talent.matching_percentage < fromAiParam.match_from) {
      return false;
    }
    if (fromAiParam.match_to && talent.matching_percentage > fromAiParam.match_to) {
      return false;
    }
    if (selectedOptionSet.set_id && talent.set_id != selectedOptionSet.set_id) {
      return false;
    }

    return true;
  };

  async function saveTableB() {
    try {
      //save table B
      await meetingApi.saveTableBFromAi(roleActive.roleId, {
        agenda_id: Number(agendaId),
        position_codes: positionsCode,
        staff_ids: union([
          ...selected,
          ...showTable.filter((item) => item.isTableB).map((i) => i.staff_id),
        ]).map((m) => ({ id: m, set_id: showTable.find((f) => f.staff_id == m)?.set_id })),
        line,
      });
      //get table B
      getFromAiTableB();

      setTable((prev) => ({
        ...prev,
        tableA: orderBy(
          union([
            ...prev.tableA,
            ...temporaryTableB.filter((item) => !selected.includes(item.staff_id)),
          ]),
          ['matching_percentage'],
          'desc'
        ),
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setSelected([]);
      setStatusTableB((prev) => ({ ...prev, isAddedFilter: false }));
      setCollapse(['filtered-result']);
    }
  }

  async function removeTableB() {
    const remainingTableB = showTable
      .filter((i) => !selected.includes(i.staff_id))
      .filter((item) => !temporaryTableB.map((i) => i.staff_id).includes(item.staff_id));
    setTemporaryTableB((prev) => prev.filter((i) => !selected.includes(i.staff_id)));
    try {
      //save table B
      await meetingApi.saveTableBFromAi(roleActive.roleId, {
        agenda_id: Number(agendaId),
        position_codes: positionsCode,
        staff_ids: remainingTableB
          .map((item) => item.staff_id)
          .map((m) => ({ id: m, set_id: showTable.find((s) => s.staff_id == m)?.set_id })),
        line,
      });
      //get table B
      getFromAiTableB(true);

      const revertToRecommendations = selected
        .map((i) => showTable.find((val) => val.staff_id === i))
        .map(({ isTableB, ...val }) => val)
        .filter((item) => checkFilterMatching(item));

      setTable((prev) => ({
        ...prev,
        tableA: orderBy(
          union([...prev.tableA, ...revertToRecommendations]),
          ['matching_percentage'],
          'desc'
        ),
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setSelected([]);
    }
  }
  return (
    <>
      {showFilterRecommendation && table === 'from-ai' && (
        <>
          <div className="d-flex w-100 justify-content-start mt-3">
            <div className="col-4 p-0">
              <Select
                value={fromAiParam.salary_grade || null}
                style={{
                  width: '100%',
                }}
                placeholder="Select"
                onChange={(e) => setFromAiParam((prev) => ({ ...prev, salary_grade: e }))}
                options={optionSearch}
              />
            </div>
            <div className="ml-2 w-100">
              <Input
                value={fromAiParam.birth_name}
                onChange={(e) => {
                  const value = e.target.value;
                  setFromAiParam((prev) => ({ ...prev, birth_name: value }));
                }}
                placeholder="Birth name"
                style={{ height: '44px' }}
              />
            </div>
          </div>
          <div className="d-flex w-100 w-100 justify-content-start mt-3">
            <div className="col-6 p-0">
              <Input
                value={fromAiParam.match_from}
                onChange={(e) => {
                  const value = e.target.value;
                  setFromAiParam((prev) => ({ ...prev, match_from: value }));
                }}
                placeholder="From (%)"
                style={{ height: '32px' }}
              />
            </div>
            <div className="ml-2 w-100">
              <Input
                value={fromAiParam.match_to}
                onChange={(e) => {
                  const value = e.target.value;
                  setFromAiParam((prev) => ({ ...prev, match_to: value }));
                }}
                placeholder="To (%)"
                style={{ height: '32px' }}
              />
            </div>
          </div>

          <div className="d-flex mt-3 mb-3">
            <BasicButton
              mode="teal"
              style={{ width: '145px', height: '32px' }}
              className="mr-2"
              onKeyDown={() => {}}
              onClick={() => getFromAiTableA(fromAiParam)}
            >
              Search
            </BasicButton>
            <BasicButton
              mode="teal"
              style={{ width: '145px', height: '32px' }}
              className="mr-2"
              disabled={isEmpty(selected)}
              onKeyDown={() => {}}
              onClick={() => {
                addFromAToB(selected);
                setSelected([]);
                setStatusTableB((prev) => ({ ...prev, isAddedFilter: true }));
              }}
            >
              Add to Filtered
            </BasicButton>
            <BasicButton
              mode="border-teal"
              style={{ width: '145px', height: '32px' }}
              onKeyDown={() => {}}
              onClick={() => {
                setFromAiParam({ salary_grade: '', birth_name: '', match_from: '', match_to: '' });
                getFromAiTableA({
                  salary_grade: '',
                  birth_name: '',
                  match_from: '',
                  match_to: '',
                });
              }}
            >
              Reset
            </BasicButton>
          </div>
        </>
      )}

      {statusTableB?.edit && table === 'filtered-result' && (
        <div className="d-flex justify-content-end mb-2 ">
          <BasicButton
            mode="border-teal"
            onKeyDown={() => {}}
            onClick={() => {
              setSelected([]);
              setStatusTableB((prev) => ({ ...prev, edit: false }));
            }}
            className="mr-2"
          >
            Cancel
          </BasicButton>
          <BasicButton
            mode="teal"
            disabled={isEmpty(selected) ? true : false}
            onKeyDown={() => {}}
            onClick={() => {
              removeTableB();
              setStatusTableB((prev) => ({ ...prev, edit: false }));
            }}
          >
            Remove
          </BasicButton>
        </div>
      )}
      {statusTableB?.isAddedFilter &&
        !statusTableB?.edit &&
        table === 'filtered-result' &&
        !isEmpty(showTable) && (
          <div className="d-flex justify-content-end mb-2">
            <BasicButton
              mode="teal"
              disabled={isEmpty(selected) ? true : false}
              onKeyDown={() => {}}
              onClick={saveTableB}
            >
              Finalize
            </BasicButton>
          </div>
        )}
      <div>
        {showTable && showTable.length > 0 && (
          <div className={`${styles.tableHeader} `}>
            {(showCheckBoxA || showCheckBoxB) && (
              <div style={{ width: '5%' }}>
                <Checkbox
                  onChange={handleSelectAllClick}
                  checked={
                    selected?.length === showTable.length && showTable.length !== 0 ? true : false
                  }
                />
              </div>
            )}
            <div style={{ width: '10%' }}></div>
            <div
              className={styles.nameHeader}
              style={{ width: showCheckBoxB && text === 'filtered' ? '40%' : '50%' }}
            >
              Name
            </div>
            {showCheckBoxB && text === 'filtered' && <div style={{ width: '10%' }}>Set</div>}
            <div style={{ width: 70 }}>SG</div>
            <div
              style={{ width: '25%', cursor: 'pointer' }}
              onKeyDown={() => {}}
              onClick={() => orderPercentage(showTable, name, order, setOrder)}
            >
              <img src={descending_sort} alt="" />
              Matching off..
            </div>
            <div style={{ width: '1%' }}></div>
          </div>
        )}
        <PopupMatchingCriteriaList
          open={openPopupCriteria}
          setOpen={setOpenPopupCriteria}
          positionName={positionName}
          {...viewCriteriaParams}
        />
        {showTable && showTable.length > 0 && (
          <div className={styles.bodyCollapse}>
            {showTable.map((item, index) => (
              <Item
                key={index}
                item={item}
                index={index}
                {...props}
                text={text}
                handleSelected={handleSelected}
                selected={selected}
                showCheckBox={showCheckBoxA || showCheckBoxB}
                table={table}
                handleViewMatchingPercentage={handleViewMatchingPercentage}
              />
            ))}
          </div>
        )}
        {showTable && showTable.length === 0 && (
          <div
            className="w-100 text-center"
            style={{
              color: '#000',
              fontSize: '12px',
              fontWeight: 500,
              lineHeight: '20px',
            }}
          >
            {table === 'from-ai' ? 'No data' : 'No filtered result has been added.'}
          </div>
        )}
      </div>
    </>
  );
};

const initOptions = [
  { id: 2, value: '1st Line', name: 'Potential 1st Line' },
  { id: 3, value: '2nd Line', name: 'Potential 2nd/3rd Line' },
  { id: 1, value: '', name: 'All' },
];

const RecommendationTable = (props) => {
  const { dataTableA, setTable, dataTableB, positionsCode, positionName, isViewModeAgenda, ...otherProps } = props;
  const [isActiveTab, setIsActiveTab] = useState('ai-active');
  const isAcitveTabRef = useRef('ai-active');
  const [loadingA1, setLoadingA1] = useState(false);
  const [loadingA2, setLoadingA2] = useState(false);
  const [loadingB1, setLoadingB1] = useState(false);
  const [loadingB2, setLoadingB2] = useState(false);
  const [showFilterRecommendation, setShowFilterRecommendation] = useState(false);
  const [showEditFiltered, setShowEditFiltered] = useState(false);
  const [collapse, setCollapse] = useState(['recommendations', 'filtered-result']);
  const roleActive = useSelector((state) => state.user.roleActive);
  const [selected, setSelected] = useState(initOptions[0]);
  const [isRemark, setIsRemark] = useState(false);
  const [totalFromTalentReview, setTotalFromTalentReview] = useState(0);
  const [totalFromAi, setTotalFromAi] = useState(0);
  const { idMeeting } = useParams();
  const { data: meetingDetail } = useGetMeeting({ idMeeting });
  const { data: meetingRole } = useGetRoleInMeeting({ idMeeting });
  const { Option } = Select;
  const [optionSets, setOptionSets] = useState([]);
  const [selectedOptionSet, setSelectedOptionSet] = useState({});
  const options = initOptions;

  useEffect(() => {
    if (otherProps?.setIsDisabledPrintBtn) {
      otherProps?.setIsDisabledPrintBtn((loadingA1 || loadingA2 || loadingB1 || loadingB2) ?? false);
    }
  }, [loadingA1, loadingA2, loadingB1, loadingB2]);

  const [fromTalentParam, setFromTalentParam] = useState({
    birth_name: '',
    from_date: '',
    to_date: '',
  });
  const [optionSearch, setOptionSearch] = useState([]);
  const [fromAiParam, setFromAiParam] = useState({
    salary_grade: '',
    birth_name: '',
    match_from: '',
    match_to: '',
  });
  const [showCheckBoxA, setShowCheckBoxA] = useState(false);
  const [temporaryTableB, setTemporaryTableB] = useState([]);
  const [dataFromAi, setDataFromAi] = useState({
    tableA: [],
    tableB: [],
  });
  const [dataFromTalentReview, setDataFromTalentReview] = useState({
    tableA: [],
    tableB: [],
  });

  const prevShowCheckBoxB = useRef(false);
  const { agendaId } = useParams();
  const isDisplayRemark =
    [
      MEETING_DETAIL_STATUS.IN_PROGRESS,
      MEETING_DETAIL_STATUS.IN_REVIEW,
      MEETING_DETAIL_STATUS.CONCLUDED,
    ].includes(meetingDetail?.status) && meetingRole?.is_secretariat_or_facilitator;

  const isNotViewAllRemark = useMemo(() => {
    return (
      [
        MEETING_DETAIL_STATUS.IN_REVIEW,
        MEETING_DETAIL_STATUS.CONCLUDED,
        MEETING_DETAIL_STATUS.IN_PROGRESS,
      ].includes(meetingDetail?.status) &&
      (meetingRole?.is_advocator ||
        meetingRole?.is_hr_partners ||
        meetingRole?.is_chairman ||
        meetingRole?.is_commitee)
    );
  }, [meetingDetail, meetingRole]);

  const hanldeSelectSetOption = (event) => {
    /*eslint eqeqeq: "off"*/
    const itemSelected = find(optionSets, (s) => s && s.set_id == event);
    setSelectedOptionSet(itemSelected);
  };
  const getDataForAiTab = useCallback(async (positionsCode, roleId) => {
    try {
      const responseSetLists = await meetingApi.getSetListSPMatchingCriteria(
        positionsCode[0],
        roleId
      );
      const resultSets = responseSetLists?.data?.result || [];
      if (!resultSets || resultSets.length === 0) return;

      setOptionSets(resultSets);
      const mainSet = find(resultSets, (set) => set && set.is_main);
      setSelectedOptionSet(mainSet ? mainSet : resultSets[0]);
    } catch (error) {
      console.error(error);
    }
  }, []);

  async function getFromAiTableA(params) {
    try {
      setLoadingA1(true);
      let _limit = '';
      if (selected.value === '2nd Line') {
        _limit = 50;
      }
      let query = {
        postions: positionsCode.join(','),
        line: selected.value,
        _limit,
        is_save: false,
        is_search_by_click: true,
      };
      if (!isNaN(Number(agendaId))) {
        query = {
          ...query,
          agenda_id: agendaId,
          ...params,
        };
      }
      if (selectedOptionSet.set_id) {
        query = {
          ...query,
          setId: selectedOptionSet.set_id,
        };
      }
      const response = await meetingApi.getTalentFromAi(roleActive.roleId, query);
      const data = get(response, 'data.result') || [];
      setTotalFromAi(data.data.length);
      const option = Array.from(new Set(data.data.map((item) => item.salary_grade)));
      setOptionSearch(
        option.map((item) => {
          return {
            value: item,
            label: item,
          };
        })
      );
      setDataFromAi((prev) => ({
        ...prev,
        tableA: data.data.map((item) => {
          return {
            ...item,
            is_talent_review: 0,
          };
        }),
      }));
    } catch (error) {
      console.log(error);
      setDataFromAi((prev) => ({
        ...prev,
        tableA: [],
      }));
    } finally {
      setLoadingA1(false);
    }
  }
  async function getFromAiTableB(isGetFromTemporaryB) {
    try {
      setLoadingB1(true);
      const query = {
        postions: positionsCode.join(','),
        line: selected.value,
        is_save: true,
        is_search_by_click: true,
        agenda_id: agendaId,
      };

      const response = await meetingApi.getTalentFromAi(roleActive.roleId, query);
      const data = get(response, 'data.result');
      setDataFromAi((prev) => ({
        ...prev,
        tableB: isGetFromTemporaryB
          ? uniqBy(
              [
                ...data.data.map((item) => {
                  return {
                    ...item,
                    is_talent_review: 0,
                    isTableB: true,
                  };
                }),
                ...temporaryTableB,
              ],
              'staff_id'
            )
          : data.data.map((item) => {
              return {
                ...item,
                is_talent_review: 0,
                isTableB: true,
              };
            }),
      }));
      setTemporaryTableB([]);
    } catch (error) {
      console.log(error);
      setDataFromAi((prev) => ({
        ...prev,
        tableB: [],
      }));
    } finally {
      setLoadingB1(false);
    }
  }

  async function getTalentReviewTableA(params) {
    try {
      setLoadingA2(true);
      let responseFromTalentReview = {};

      responseFromTalentReview = await talentReviewApi.getRecommendationFromTalentReview(
        true,
        positionsCode.join(','),
        false,
        !isNaN(Number(agendaId)) ? agendaId : '',
        params?.birth_name ? params?.birth_name : '',
        params?.from_date ? moment(params?.from_date, 'DD-MM-YYYY').toISOString() : '',
        params?.to_date ? moment(params?.to_date, 'DD-MM-YYYY').toISOString() : ''
      );

      const data = get(responseFromTalentReview, 'data.result');
      setTotalFromTalentReview(data.total);
      setDataFromTalentReview((prev) => ({ ...prev, tableA: data.data }));
    } catch (error) {
      console.log(error);
      setDataFromTalentReview((prev) => ({ ...prev, tableA: [] }));
    } finally {
      setLoadingA2(false);
    }
  }

  async function getTalentReviewTableB(isGetFromTemporaryB) {
    try {
      setLoadingB2(true);
      const responseFromTalentReview = await talentReviewApi.getRecommendationFromTalentReview(
        true,
        positionsCode.join(','),
        true,
        agendaId,
        '',
        '',
        ''
      );
      const data = get(responseFromTalentReview, 'data.result');
      setDataFromTalentReview((prev) => ({
        ...prev,
        tableB: isGetFromTemporaryB
          ? uniqBy(
              [...data.data.map((item) => ({ ...item, isTableB: true })), ...temporaryTableB],
              'staff_id'
            )
          : data.data.map((item) => ({ ...item, isTableB: true })),
      }));

      setTemporaryTableB([]);
    } catch (error) {
      console.log(error);
      setDataFromTalentReview((prev) => ({
        ...prev,
        tableB: [],
      }));
    } finally {
      setLoadingB2(false);
    }
  }

  useEffect(() => {
    if (!roleActive.roleId || !positionsCode || positionsCode.length <= 0) return;
    if (isActiveTab === 'ai-active') {
      setTable((prev) => ({
        ...prev,
        tableA: dataFromAi.tableA,
        tableB: isNaN(Number(agendaId)) ? [] : dataFromAi.tableB,
      }));
    } else {
      setTable((prev) => ({
        ...prev,
        tableA: dataFromTalentReview.tableA,
        tableB: isNaN(Number(agendaId)) ? [] : dataFromTalentReview.tableB,
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isActiveTab,
    JSON.stringify(dataFromAi),
    JSON.stringify(dataFromTalentReview),
    agendaId,
    roleActive.roleId,
    JSON.stringify(positionsCode),
  ]);

  useEffect(() => {
    if (
      !roleActive.roleId ||
      !positionsCode ||
      positionsCode.length <= 0 ||
      !selectedOptionSet.set_id
    )
      return;
    getFromAiTableA();
    if (isNaN(Number(agendaId))) return;
    getFromAiTableB();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptionSet.set_id, selected, agendaId, JSON.stringify(positionsCode)]);

  useEffect(() => {
    if (!roleActive.roleId || !positionsCode || positionsCode.length <= 0) return;
    getTalentReviewTableA();
    if (isNaN(Number(agendaId))) return;
    getTalentReviewTableB();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(positionsCode), roleActive.roleId]);

  useEffect(() => {
    if (!roleActive.roleId || !positionsCode || positionsCode.length <= 0) return;
    getDataForAiTab(positionsCode, roleActive.roleId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(positionsCode), roleActive.roleId]);

  const onchange = (e) => {
    setCollapse(e);
  };
  function addFromAToB(ids) {
    const temporaryTableB = dataTableA.filter((item) => ids.includes(item.staff_id));
    setTemporaryTableB(temporaryTableB);
    if (isActiveTab === 'ai-active') {
      setDataFromAi((prev) => ({
        tableA: prev.tableA.filter((item) => !ids.includes(item.staff_id)),
        tableB: orderBy(
          [...cloneDeep(prev.tableB), ...prev.tableA.filter((item) => ids.includes(item.staff_id))],
          ['matching_percentage'],
          ['desc']
        ),
      }));
    } else {
      setDataFromTalentReview((prev) => ({
        tableA: prev.tableA.filter((item) => !ids.includes(item.staff_id)),
        tableB: orderBy(
          [...cloneDeep(prev.tableB), ...prev.tableA.filter((item) => ids.includes(item.staff_id))],
          ['matching_percentage'],
          ['desc']
        ),
      }));
    }
  }

  function orderPercentage(tableOrder, setTableOrder, order, setOrder) {
    let sorted_array = orderBy(tableOrder, ['matching_percentage'], [order]);
    setDataFromAi((prev) => ({ ...prev, [setTableOrder]: sorted_array }));
    setOrder(order === 'asc' ? 'desc' : 'asc');
  }
  const [statusTableB, setStatusTableB] = useState({
    edit: false,
    isAddedFilter: false,
  });
  const showCheckBoxB = useMemo(() => {
    if (statusTableB.isAddedFilter) return true;
    if (statusTableB.edit) return true;
  }, [statusTableB]);

  return (
    <>
      <Wrapper>
        <Collapse
          bordered={false}
          defaultActiveKey={collapse}
          onChange={onchange}
          activeKey={collapse}
        >
          <Panel
            header={
              <div className={styles.collapse}>
                <div className={styles.collapseHeader} style={{ backgroundColor: '#637AB8' }}>
                  <div className={styles.left}>Recommendations</div>
                  <div>
                    {!isNaN(Number(agendaId)) && !isViewModeAgenda && (
                      <img
                        src={recommendation_filter}
                        style={{ marginRight: '20px', pointerEvents: 'all' }}
                        onKeyDown={() => {}}
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowCheckBoxA(showFilterRecommendation ? false : true);
                          setShowFilterRecommendation(!showFilterRecommendation);
                        }}
                        alt=""
                      />
                    )}

                    <img
                      src={chevron_down}
                      alt=""
                      className={collapse.includes('recommendations') ? styles.animate : ``}
                    />
                  </div>
                </div>
              </div>
            }
            key="recommendations"
            showArrow={false}
          >
            <div className={styles.collapse}>
              <div className={styles.tab}>
                <div
                  className={isActiveTab === 'ai-active' ? styles.active : ''}
                  onKeyDown={() => {}}
                  onClick={() => {
                    setIsActiveTab('ai-active');
                    isAcitveTabRef.current = 'ai-active';
                  }}
                >
                  From AI ({totalFromAi})
                </div>
                <div
                  className={isActiveTab === 'talent-review-active' ? styles.active : ''}
                  onKeyDown={() => {}}
                  onClick={() => {
                    setIsActiveTab('talent-review-active');
                    isAcitveTabRef.current = 'talent-review-active';
                  }}
                >
                  From Talent Review ({totalFromTalentReview})
                </div>
              </div>
              <div className={styles.body}>
                {isActiveTab === 'ai-active' ? (
                  <>
                    {loadingA1 ? (
                      <div className="d-flex justify-content-center">
                        <div className="spinner-border text-primary" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div
                          style={{
                            fontWeight: 500,
                            fontSize: '14px',
                            marginBottom: '15px',
                            textAlign: 'center',
                          }}
                        >
                          Missing SG/JG data might impact the AI recommendation list
                        </div>
                        <div className={styles.set_options}>
                          <div className={styles.label}>Choose Matching Criteria Set</div>
                          <Select
                            className="recommendation-table_dropdown"
                            value={selectedOptionSet?.set_id}
                            onChange={hanldeSelectSetOption}
                          >
                            {optionSets &&
                              optionSets.length > 0 &&
                              optionSets.map((item, index) => (
                                <Option
                                  className="recommendation-table-options"
                                  key={index}
                                  onClick={() => setSelected(item)}
                                  value={item.set_id}
                                >
                                  <div className="recommendation-table_custom_label">
                                    <span>
                                      Set {item.set_number}: {item.set_name}
                                    </span>
                                    {item.is_main && <span className={styles.box_main}> Main</span>}
                                  </div>
                                </Option>
                              ))}
                          </Select>
                        </div>
                        <div className={styles.top}>
                          {options &&
                            options.length > 0 &&
                            options.map((item, index) => (
                              <div
                                key={index}
                                className={item.id === selected.id ? styles.activeFilter : ''}
                                style={{ backgroundColor: item.id !== selected.id && '#fff' }}
                                onKeyDown={() => {}}
                                onClick={() => setSelected(item)}
                              >
                                {item.name}
                              </div>
                            ))}
                          {(isDisplayRemark || !isNotViewAllRemark) && (
                            <div
                              onKeyDown={() => {}}
                              onClick={() => setIsRemark(true)}
                              style={{ backgroundColor: '#fff' }}
                            >
                              <img src={sp_recomendation_table_comment} alt="" />
                            </div>
                          )}
                        </div>
                        <div className={styles.table}>
                          <Table
                            showFilterRecommendation={showFilterRecommendation}
                            text="recommendation"
                            table="from-ai"
                            addFromAToB={addFromAToB}
                            getFromAiTableB={getFromAiTableB}
                            roleActive={roleActive}
                            agendaId={agendaId}
                            positionsCode={positionsCode}
                            positionName={positionName}
                            fromAiParam={fromAiParam}
                            setFromAiParam={setFromAiParam}
                            optionSearch={optionSearch}
                            getFromAiTableA={getFromAiTableA}
                            showTable={dataTableA}
                            setTable={setDataFromAi}
                            orderPercentage={orderPercentage}
                            showCheckBoxA={showCheckBoxA}
                            setshowCheckBoxA={setShowCheckBoxA}
                            temporaryTableB={temporaryTableB}
                            setTemporaryTableB={setTemporaryTableB}
                            setStatusTableB={setStatusTableB}
                            name="tableA"
                          />
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {loadingA2 ? (
                      <div className="d-flex justify-content-center">
                        <div className="spinner-border text-primary" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <FromTalentReview
                        {...props}
                        positionsCode={positionsCode}
                        text="recommendation"
                        table="tableA"
                        fromTalentParam={fromTalentParam}
                        setFromTalentParam={setFromTalentParam}
                        showFilterRecommendation={showFilterRecommendation}
                        showEditFiltered={showEditFiltered}
                        getTalentReviewTableA={getTalentReviewTableA}
                        roleActive={roleActive}
                        addFromAToB={addFromAToB}
                        showTable={dataTableA}
                        setTable={setDataFromTalentReview}
                        showCheckBoxA={showCheckBoxA}
                        setshowCheckBoxA={setShowCheckBoxA}
                        setStatusTableB={setStatusTableB}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </Panel>
          {!isNaN(Number(agendaId)) && (
            <Panel
              header={
                <div className={styles.collapse}>
                  <div className={styles.collapseHeader} style={{ backgroundColor: '#637AB8' }}>
                    <div className={styles.left}>Filtered Result</div>
                    <div>
                      {!isEmpty(dataTableB) && !isViewModeAgenda && (
                        <img
                          src={edit_filtered_result}
                          style={{ marginRight: '20px', pointerEvents: 'all' }}
                          onKeyDown={() => {}}
                          onClick={(e) => {
                            e.stopPropagation();
                            prevShowCheckBoxB.current = showCheckBoxB;
                            setStatusTableB((prev) => ({ ...prev, edit: true }));
                          }}
                          alt=""
                        />
                      )}
                      <img
                        src={chevron_down}
                        alt=""
                        className={collapse.includes('filtered-result') ? styles.animate : ``}
                      />
                    </div>
                  </div>
                </div>
              }
              key="filtered-result"
              showArrow={false}
            >
              <div className={styles.collapse}>
                <div className={styles.body} style={{ paddingTop: '0px' }}>
                  {isActiveTab === 'ai-active' ? (
                    <div className={styles.table}>
                      {loadingB1 ? (
                        <div className="d-flex justify-content-center">
                          <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <Table
                          text="filtered"
                          showEditFiltered={showEditFiltered}
                          setShowEditFiltered={setShowEditFiltered}
                          table="filtered-result"
                          addFromAToB={addFromAToB}
                          roleActive={roleActive}
                          getFromAiTableA={getFromAiTableA}
                          getTalentReviewTableB={getTalentReviewTableB}
                          getFromAiTableB={getFromAiTableB}
                          agendaId={agendaId}
                          positionsCode={positionsCode}
                          positionName={positionName}
                          showTable={dataTableB}
                          setTable={setDataFromAi}
                          line={selected.value}
                          orderPercentage={orderPercentage}
                          showCheckBoxB={showCheckBoxB}
                          temporaryTableB={temporaryTableB}
                          setTemporaryTableB={setTemporaryTableB}
                          prevShowCheckBoxB={prevShowCheckBoxB}
                          setStatusTableB={setStatusTableB}
                          statusTableB={statusTableB}
                          getTalentReviewTableA={getTalentReviewTableA}
                          fromAiParam={fromAiParam}
                          setCollapse={setCollapse}
                          selectedOptionSet={selectedOptionSet}
                          name="tableB"
                        />
                      )}
                    </div>
                  ) : (
                    <>
                      {loadingB2 ? (
                        <div className="d-flex justify-content-center">
                          <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <FromTalentReview
                          {...props}
                          positionsCode={positionsCode}
                          isAcitveTab={isActiveTab}
                          text="filtered"
                          table="tableB"
                          fromTalentParam={fromTalentParam}
                          setFromTalentParam={setFromTalentParam}
                          showFilterRecommendation={showFilterRecommendation}
                          showEditFiltered={showEditFiltered}
                          getTalentReviewTableB={getTalentReviewTableB}
                          roleActive={roleActive}
                          setShowEditFiltered={setShowEditFiltered}
                          addFromAToB={addFromAToB}
                          showTable={dataTableB}
                          setTable={setDataFromTalentReview}
                          getTalentReviewTableA={getTalentReviewTableA}
                          showCheckBoxB={showCheckBoxB}
                          temporaryTableB={temporaryTableB}
                          setTemporaryTableB={setTemporaryTableB}
                          prevShowCheckBoxB={prevShowCheckBoxB}
                          setStatusTableB={setStatusTableB}
                          statusTableB={statusTableB}
                          setCollapse={setCollapse}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </Panel>
          )}
        </Collapse>
      </Wrapper>

      {isRemark && (
        <RemarkTableDialog
          isRemark={isRemark}
          setIsRemark={setIsRemark}
          isViewModeAgenda={isViewModeAgenda}
        />
      )}
    </>
  );
};

Item.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  text: PropTypes.string,
  handleSelected: PropTypes.func,
  selected: PropTypes.array,
  showCheckBox: PropTypes.bool,
  handleViewMatchingPercentage: PropTypes.func,
};

Table.propTypes = {
  showTable: PropTypes.array,
  setTable: PropTypes.func,
  text: PropTypes.string,
  showFilterRecommendation: PropTypes.bool,
  table: PropTypes.string,
  addFromAToB: PropTypes.func,
  getFromAiTableB: PropTypes.func,
  roleActive: PropTypes.object,
  positionsCode: PropTypes.array,
  positionName: PropTypes.string,
  agendaId: PropTypes.string,
  fromAiParam: PropTypes.object,
  setFromAiParam: PropTypes.func,
  optionSearch: PropTypes.array,
  getFromAiTableA: PropTypes.func,
  orderPercentage: PropTypes.func,
  line: PropTypes.string,
  showCheckBoxA: PropTypes.bool,
  setTemporaryTableB: PropTypes.func,
  temporaryTableB: PropTypes.array,
  setStatusTableB: PropTypes.func,
  statusTableB: PropTypes.object,
  showCheckBoxB: PropTypes.bool,
  setCollapse: PropTypes.func,
  selectedOptionSet: PropTypes.object,
};

RecommendationTable.propTypes = {
  dataTableA: PropTypes.array,
  setTable: PropTypes.func,
  dataTableB: PropTypes.array,
  positionsCode: PropTypes.array,
  positionName: PropTypes.string,
  isViewModeAgenda: PropTypes.bool,
};

export default RecommendationTable;
