import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import { notification } from 'antd';
import { isArray, get } from 'lodash';
import Axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { AiSpin, BasicButton } from '../../assets/common';
import { my_talent_x_logo, petronas_logo } from '../../assets/img';
import { loginRequest } from '../../msalConfig';
import userApi from '../../services/userApi';
import { updateIsAfterLogin } from '../../store/afterLoginSlice';
import { setData, setRoleActive, setRoles, setUserId, setUsername } from '../../store/userSlice';
import { ACTION_LOG, ERROR_MESSAGE, LOCAL_STORAGE, USER_NOT_EXIST } from '../../utils/constants';
import { confirmAutoLogout } from '../../utils/helper';
import styles from './login-page.module.scss';
import CustomAntModal from '../../components/CustomAntModal/CustomAntModal';

const LoginPage = () => {
  const { instance } = useMsal();
  const currentAccount = instance.getActiveAccount();
  const history = useHistory();
  const dispatch = useDispatch();
  const roleActive = useSelector((state) => state.user.roleActive);
  const username = useSelector((state) => state.user.username);
  const [loading, setLoading] = useState(false);
  const isLogin = Boolean(Number(process.env.REACT_APP_BYPASS_LOGIN) || 0);
  const [emailInput, setEmailInput] = useState('');
  const [open, setOpen] = useState(false);

  const getEmployee = useCallback(
    async (userId) => {
      try {
        setLoading(true);
        const isLogin = localStorage.getItem(LOCAL_STORAGE.IS_LOGIN);
        const response = await userApi.getUserById(userId);
        userApi.logAction({ url: '', type: ACTION_LOG.LOGIN });
        const data = response?.data?.result;
        if (response.status === 200 && data) {
          if (!roleActive.roleId) {
            dispatch(setRoleActive(isArray(data?.roles) ? data.roles[0] : null));
          }
          dispatch(setRoles(data?.roles));
          dispatch(setData(data));
          dispatch(setUsername(data?.employeeName));
          history.push('/homepage');
          if (!isLogin) dispatch(updateIsAfterLogin(true));
        }
      } catch (err) {
        const res = err.response;
        console.log(err);
        if (res?.data?.message === ERROR_MESSAGE || res?.data?.errors === USER_NOT_EXIST) {
          notification.error({
            message: 'User not exist in system',
            duration: 5,
          });
          localStorage.clear();
        } else {
          notification.error({
            message: 'Something went wrong',
            duration: 5,
          });
          localStorage.clear();
        }
      }
      setLoading(false);
    },
    [dispatch, history, roleActive.roleId]
  );

  useEffect(() => {
    if (currentAccount?.name) {
      getEmployee();
    }
    if (currentAccount?.name && username && roleActive.roleId) {
      return history.push('/homepage');
    }
  }, [currentAccount?.name, getEmployee, history, roleActive?.roleId, username]);

  const handleClickLogin = async () => {
    // Clear local store before user login
    confirmAutoLogout();

    if (!isLogin) {
      instance.loginRedirect(loginRequest);
    } else {
      try {
        const response = await userApi.byPassLogin(emailInput);
        const data = response?.data?.result;
        if (response.status === 200 && data) {
          const userId = isArray(data) ? data[0].id : null;
          dispatch(setUserId(userId));
          await getEmployee(userId);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  async function getRequestGuide() {
    try {
      const res = await userApi.getTokenImage({ Authorization: process.env.REACT_APP_SERVER_KEY });
      const token = get(res, 'data.result');
      const url = process.env.REACT_APP_REQUEST_GUIDE + '?' + token;
      const config = { responseType: 'blob' };
      Axios.get(url, config)
        .then((response) => {
          const href = window.URL.createObjectURL(response.data);
          const newWindow = window.open(href, '_blank');
          setTimeout(function () {
            newWindow.document.title = 'User Manual for IGA Integration';
          }, 1000);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <>
      <CustomAntModal
        centered
        hideDefaultBtn
        open={open}
        setOpen={setOpen}
        title="Step to request access"
        width={730}
        closeType={1}
        styles={{
          borderRadius: 6,
          titleFontSize: 20,
          titleFontWeight: 500,
          closePosition: 45,
          containerPaddingLeftRight: 45,
          containerPaddingTopBottom: 45,
          headerPaddingBottom: 30,
        }}
      >
        <div className={styles.modalContent}>
          <div>
            <div className={styles.step}>STEP 1</div>
            <p>
              Download User Access Request Form{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={process.env.REACT_APP_REQUEST_LOGIN_ACCESS_STEP_1}
              >
                here
              </a>{' '}
              and get approval from related business focal.
            </p>
          </div>
          <div>
            <div className={styles.step}>STEP 2</div>
            <p>
              Once approved, click{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={process.env.REACT_APP_REQUEST_LOGIN_ACCESS_STEP_2}
              >
                here
              </a>{' '}
              to request your access.
            </p>
            <p>
              You are required to attach the approved User Access Request Form in Attachment
              section.
            </p>
          </div>
          <div>
            <div className={styles.step}>STEP 3</div>
            <p>Wait for your request to be approved.</p>
          </div>
          <div>
            <div className={styles.step}>STEP 4</div>
            <p>Once the request is approved, log into myTalentX.</p>
          </div>
        </div>
      </CustomAntModal>
      <MsalAuthenticationTemplate />
      <div className={styles.wrapper}>
        <div className={styles.leftSide}>
          <img src={my_talent_x_logo} alt="logo" />
        </div>
        <div className={styles.rightSide}>
          <div className={styles.content}>
            <img src={petronas_logo} alt="logo" />
            <div className={styles.title}>Hello Again!</div>
            {!loading ? (
              <>
                {isLogin && (
                  <input
                    className={styles.emailInput}
                    value={emailInput}
                    onChange={(e) => setEmailInput(e.target.value)}
                    placeholder="Enter your email"
                  />
                )}
                <BasicButton mode="teal" className={styles.loginBtn} onClick={handleClickLogin}>
                  Login
                </BasicButton>
              </>
            ) : (
              <AiSpin className={styles.loading}></AiSpin>
            )}
            <div className={styles.requestAccess}>
              Don't have access? Please <span onClick={() => setOpen(true)}>Request Access</span>{' '}
              here.
            </div>
            <div className={styles.requestAccess} style={{ marginTop: '10px' }}>
              Refer{' '}
              <span onClick={getRequestGuide} onKeyDown={() => {}}>
                Request Guide
              </span>{' '}
              for assistance if needed
            </div>
            <div className={styles.contact}>
              For inquiries and assistance, please contact ICT Service Desk at <br />
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://outlook.office365.com/mail/"
              >
                ict.servicedesk@petronas.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default LoginPage;
